import { chunkArray } from '@helpers/util';
import moment from 'moment';

export const formattingOptionHandler = ({ arr }) => {
    return arr?.map((item) => {
        return {
            label: item?.name,
            value: item?.name,
            id: item?.id,
        };
    });
};

export const formattingAccountsOptionHandler = ({ arr }) => {
    return arr?.map((item) => {
        return {
            label: item?.name,
            value: item?.name,
            id: item?.id,
            ecommerce: item?.ecommerce?.name,
        };
    });
};

export const formattingScheduleHandler = ({ schedules }) => {
    return schedules?.map((item) => {
        const duration = moment.duration(moment(new Date(item?.endedAt)).diff(new Date(item?.startedAt)));
        const hours = duration.asHours();
        return {
            eventName: item?.title,
            accounts: item?.accountLivestreams,
            studio: item?.studio?.name,
            hosts: item?.hosts,
            start: new Date(item?.startedAt),
            end: new Date(item?.endedAt),
            startedAt: item?.startedAt,
            endedAt: item?.endedAt,
            status: item?.scheduleType,
            eventId: item?.id,
            timeOffset: hours,
        };
    });
};

export const optionsFilterFormatted = (arr) => {
    let result = [];
    if (arr) {
        //------- ADD ALL ---------------------

        result = [{ name: 'All', value: '' }, ...arr];
    }

    return result;
};

// ---------------------- SECTION HEAD ----------------------------------------------

export const isFilterActive = ({ mainState }) => {
    const { brand, ecommerce, studio, host, account } = mainState;
    return brand || ecommerce || studio || host || account?.length > 0;
};

export const getListHost = (hosts) => {
    const hostsFirstName = hosts?.map((item) => item?.name.split(' ')[0]);
    return hostsFirstName.toString();
};

export const formattingInvalidData = (invalidData) => {
    return chunkArray(
        invalidData.flatMap(({ row, error }) =>
            error.map((err) => ({
                row,
                error: err.type === 'missing field' ? `Missing fields: ${err.field.join(', ')}` : err.message,
            }))
        ),
        20
    );
};
