import queryString from 'query-string';
import { get, find, isEmpty, lowerCase } from 'lodash';
const fuzzball = require('fuzzball');

const valueFormatted = ({ type, value, valueName }) => {
    const obj = {
        searchparam: `${valueName}.ilike.${value}`,
        sort: `${valueName}.${value}`,
    };

    return obj[type] || value;
};
export const updateFilterQueriesHandler = ({ location, type, value, navigate, valueName }) => {
    const { search, pathname } = location;
    const currentSearch = queryString.parse(search);

    const obj = {
        ...currentSearch,
        [type]: valueFormatted({ value, type, valueName }),
    };

    Object.keys(obj).forEach((key) => {
        if (obj[key] === '' || obj[key] === '[]') {
            delete obj[key];
        }
    });

    const queries = queryString.stringify(obj);

    navigate(`${pathname}?${decodeURIComponent(queries)}`);
};

export const numberToArray = (number) => {
    const arr = [];
    for (let i = 0; i < number; i++) {
        arr.push(i);
    }

    return arr;
};

export const currencyFormatting = (value) => new Intl.NumberFormat('id-ID', { currency: 'idr' }).format(value);

export const searchData = ({ location, tableList }) => {
    /* eslint-disable */

    const parseData = queryString.parse(location?.search);
    const type = parseData?.searchparam?.substring(0, parseData?.searchparam.indexOf('ilike') - 1);
    const name = parseData?.searchparam?.substring(
        parseData?.searchparam.indexOf('ilike') + 6,
        parseData?.searchparam?.length
    );

    return {
        type: get(find(tableList, { dataManipulationValue: type }), 'name'),
        name,
    };
};

export const numberToCurrency = (number) => {
    const digit = `${number}`?.length;

    if (digit < 4) {
        return number;
    }

    if (digit < 9) {
        return number / 1000000;
    }

    if (digit < 13) {
        return number / 1000000000;
    }

    if (digit < 16) {
        return number / 1000000000000;
    }
};

export const numberToNominalText = (number) => {
    const digit = `${number}`?.length;

    if (digit < 4) {
        return '';
    }

    if (digit < 7) {
        return 'Ribu';
    }

    if (digit < 9) {
        return 'Juta';
    }

    if (digit < 13) {
        return 'Milyar';
    }

    if (digit < 16) {
        return 'Triliun';
    }
};

export const removeNonNumeric = (num) => num?.toString().replace(/[^0-9]/g, '');

export const separatorComma = (num) => {
    const addCommas = removeNonNumeric(num)
        ?.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return addCommas;
};

export const filteringDataByFuzzball = ({ options, value }) => {
    const newOptions = [...options];

    if (isEmpty(value)) return options;

    return newOptions?.filter((item) => {
        const score = fuzzball.ratio(lowerCase(value), lowerCase(item?.value));

        return score >= 50 || lowerCase(item?.value).includes(lowerCase(value));
    });
};

export const isMobileHandler = () => {
    return window.innerWidth < 768;
};

export const separatorNumber = (num) => {
    return `Rp ${(num || 0).toLocaleString('id-ID')}`;
};

export const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
};
