//---------------- 3RD PARTY LIBRARY ------------------------------------------
import { createContext, useContext, useState } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { FileUploader } from 'react-drag-drop-files';
import CountUp from 'react-countup';
import { useDispatch } from 'react-redux';

//---------------- GLOBAL COMPONENT ------------------------------------------
import ModalTemplate from '@components/modal';
import ProgressBar from '@components/progressBar';
import { DownloadIcon2, FileIcon, FileIconFill, UploadIconSm } from '@assets/icons';

//---------------- LOCAL COMPONENT ------------------------------------------
import InvalidDataTable from '../invalidDataTable';

//---------------- UTILS ------------------------------------------
import { initialState } from './const';
import './style.scss';
import { downloadTemplateSchedule, uploadMassSchedule } from '@pages/schedule/helpers/crud';
import { ScheduleContext } from '@pages/schedule';
import { formattingInvalidData } from '@pages/schedule/helpers/utlis';

export const UploadPopUpContext = createContext();

const UploadPopUp = ({ onChangeHeadState }) => {
    const dispatch = useDispatch();
    const { refetchScheduleHandler } = useContext(ScheduleContext);
    const [state, setState] = useState(initialState);
    const { isDragging, uploadState, invalidData } = state;

    const onChangeState = (newState) => setState({ ...state, ...newState });

    return (
        <UploadPopUpContext.Provider
            value={{
                onChangePopUpState: onChangeState,
                popUpState: state,
            }}
        >
            <ModalTemplate onClose={() => onChangeHeadState({ isOpenUploadPopup: false })}>
                <Box className="upload-target__container">
                    {/** ======================== HEAD ========================= */}
                    <Flex className="head">
                        <Text
                            className="title"
                            onClick={() => onChangeHeadState({ isOpenUploadPopup: false })}
                            role="button"
                        >
                            <ArrowBackIcon marginRight="16px" />
                            {uploadState === 'invalid-data' ? 'Invalid Data' : 'Upload File'}
                        </Text>
                        {uploadState !== 'invalid-data' && (
                            <Button
                                className="download-template__button"
                                onClick={() => downloadTemplateSchedule({ dispatch })}
                            >
                                <Box>
                                    <DownloadIcon2 color="#0066b1" />
                                </Box>
                                <Text className="title-download">Download Template</Text>
                            </Button>
                        )}
                    </Flex>

                    {/* ======================== UPLOAD SECTION ========================= */}
                    {uploadState === '' && (
                        <Box className="body">
                            <Flex className="upload-box">
                                <FileUploader
                                    name="file"
                                    onDraggingStateChange={(dragging) => onChangeState({ isDragging: dragging })}
                                    handleChange={(files) =>
                                        uploadMassSchedule({ files, dispatch, onChangeState, refetchScheduleHandler })
                                    }
                                    classes="file-uploader__drag-and-drop-only"
                                    types={['xlsx', 'csv']}
                                    disabled
                                />

                                {isDragging && (
                                    <Box className="drag-zone__actived">
                                        <Text className="text">"Drop file here"</Text>
                                    </Box>
                                )}

                                <FileIcon />
                                <Text className="upload-text">Drag and Drop atau pilih file Exel untuk di upload</Text>
                                <Button className="upload-button">
                                    <FileUploader
                                        name="file"
                                        onDraggingStateChange={(dragging) => onChangeState({ isDragging: dragging })}
                                        handleChange={(files) =>
                                            uploadMassSchedule({
                                                files,
                                                dispatch,
                                                onChangeState,
                                                refetchScheduleHandler,
                                            })
                                        }
                                        classes="file-uploader__drag-and-drop-only"
                                        types={['xlsx', 'csv']}
                                    />
                                    <UploadIconSm />
                                    Upload File
                                </Button>
                            </Flex>
                        </Box>
                    )}
                    {uploadState === 'process' && (
                        <Box className="upload-process__box">
                            <Box className="icon">
                                <FileIconFill color="var(--main-color)" size={36} />
                            </Box>
                            <Box className="progress">
                                <Flex className="process-text">
                                    <Text className="text">Processing data..</Text>
                                    <Text className="text">
                                        <CountUp end={87} duration={8} />%
                                    </Text>
                                </Flex>
                                <ProgressBar color="var(--main-color)" width={'100%'} />
                            </Box>
                        </Box>
                    )}

                    {uploadState === 'invalid-data' && (
                        <InvalidDataTable invalidData={formattingInvalidData(invalidData)} />
                    )}
                </Box>
            </ModalTemplate>
        </UploadPopUpContext.Provider>
    );
};

export default UploadPopUp;
