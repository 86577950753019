import { Text } from '@chakra-ui/react';

export const tableList = [
    {
        name: 'Row',
        value: 'row',
        width: 50,
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'Error',
        value: 'error',
        width: 200,
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
];
