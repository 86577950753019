//---------------- 3RD PARTY LIBRARY ------------------------------------------
import { Box } from '@chakra-ui/react';
import { useState } from 'react';

//---------------- GLOBAL COMPONENT ------------------------------------------
import TableTemplate from '@components/tableTemplate';

//---------------- UTILS ------------------------------------------
import { tableList } from './const';
import './style.scss';

const InvalidDataTable = ({ invalidData }) => {
    const limit = 20;
    const [state, setState] = useState({
        page: 1,
        data: invalidData[0],
    });

    const onChangeState = (newState) => setState({ ...state, ...newState });

    const handlePageClick = ({ selected }) => {
        onChangeState({ data: invalidData[selected], page: selected + 1 });
    };

    const { page, data } = state;

    return (
        <Box className="invalid-data__table">
            <TableTemplate
                tableList={tableList}
                data={data}
                dataCount={data?.length}
                totalData={invalidData?.flat().length}
                page={page}
                totalPage={Math.ceil(invalidData?.flat().length / limit)}
                handlePageClick={handlePageClick}
            />
        </Box>
    );
};

export default InvalidDataTable;
